const refiner_id = '57555ad0-25a2-11ec-98de-c5301acce116';

window._refinerQueue = window._refinerQueue || [];
function _refiner() {
  _refinerQueue.push(arguments);
}
_refiner('setProject', refiner_id);
(function() {
  var a = document.createElement('script');
  a.type = 'text/javascript';
  a.async = !0;
  a.src = 'https://js.refiner.io/v001/client.js';
  var b = document.getElementsByTagName('script')[0];
  b.parentNode.insertBefore(a, b);
})();
